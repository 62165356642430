import loadable from '@loadable/component'
import React, { FunctionComponent, useState } from 'react'
import { Collapse } from 'react-collapse'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import { ChangeLogType } from 'Shared/utils/getChangeLogData'
import { t } from 'ttag'

const ChangeLogTableRow = loadable(() => import(`./ChangeLogTableRow`))

export interface ChangeLogTableType {
  version: string
  data: ChangeLogType[]
}

const rootClass = `change-log-table`

const ChangeLogTable: FunctionComponent<ChangeLogTableType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className='pux-container'>
      <div
        className={`${rootClass}${isOpened ? ` is-opened` : ``}`}
        onClick={() => setIsOpened(!isOpened)}
      >
        <PuxIconBuilder icon={`ChevronIcon`} />
        <h2>{props.version}</h2>
      </div>
      <Collapse isOpened={isOpened}>
        <div className={`pux-wysiwyg ${rootClass}-content`}>
          <table>
            <tbody>
              <tr>
                <td>{t`Redmine.ChangeLog.Type`}</td>
                <td>{t`Redmine.ChangeLog.Subject`}</td>
                <td>{t`Redmine.ChangeLog.Build`}</td>
                <td>{t`Redmine.ChangeLog.Status`}</td>
              </tr>
              {props.data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <ChangeLogTableRow data={item} />
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
  )
}

export default ChangeLogTable
